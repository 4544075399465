<template>
    <div class="home_box">
        <!-- 头部banner -->
        <banner :subtitle="subtitle" :logoFlag="logoFlag" :imgSrc="imgSrc"
         ></banner>
        <!-- 主要内容 -->
        <div class="content">
            <div class="cont" v-class="'cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="我们的服务" subtitle="Service"></partTitle>
                <div class="second serve" 
                     v-class="'second serve animate__animated animate__fadeInup animate__block'" 
                     v-for="(item, index) in serveData" 
                     @click="routeClick(index)" 
                     :key="index">
                    <span>{{item.mainTitle}}</span>
                    <div class="line"></div>
                    <p>{{item.subTitle}}</p>
                </div>
            </div>
            <div class="cont">
                <partTitle title="我们的优势" subtitle="Advantage"></partTitle>
                <img src="../../static/images/home/advantage.png" alt="" class="advantage_img"
                v-class="'advantage_img animate__animated animate__fadeInup animate__block'">
                <div v-for="(item, index) in advantageData" :key="index" class="second"
                v-class="'second advantage animate__animated animate__fadeInup animate__block'">
                    <img :src="item.src" alt=""
                    >
                    <span >{{item.title}}</span>
                    <p>{{item.cont}}</p>
                </div>
            </div>
            <div class="about" v-class="'cont about animate__animated animate__fadeInup animate__block'">
                <partTitle title="关于我们" subtitle="About Us"></partTitle>
                <div class="second">
                    <p>“和行约车”是江汽集团战略投资业务，致力于为用户提供安全、快捷、舒适、绿色、满意的一站式智能出行解决方案。</p>
                    <p>“和行约车”平台采用自营车辆，配备专职司机。平台、车、人三证齐全；司机经过严格招募筛选、系统培训认证后上岗。</p>
                    <p>平台将不断提升用户体验，共创社会价值，打造开放、共享、和谐的移动出行新生态。</p>
                </div>
            </div>
            <div class="contact" v-class="'cont contact animate__animated animate__fadeInup animate__block'" >
                <partTitle title="联系我们" subtitle="Contact"></partTitle>
                <div class="second">
                    <div class="name">合肥和行科技有限公司</div>
                    <p>地址：合肥市经开区紫蓬路1325号</p>
                    <p>电话：400-160-8899</p>
                    <p>邮箱：kf@jachx.com</p>
                    <img @click="handleClick(1)" src="../../static/images/home/wechat-su.png" alt="">
                    <span @click="handleClick(2)">小程序</span>
                    <div class="app">
                        <span @click="handleClick(3)">乘客端APP</span>
                        <span @click="handleClick(4)">司机端APP</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部footer -->
        <foot :time="time"></foot>
        <!-- mask -->
        <div class="mask animate__animated animate__slideInDown" v-show="flag" @click="maskClick" @touchmove.prevent>
            <img :src="codeImg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logoFlag: true,
            subtitle: "品质生活·智慧和行",
            imgSrc: require('@/assets/images/bg.png'),
            flag: false,
            codeImg: '',
            serveData: [{
                mainTitle: "快车",
                subTitle: "安全合规 拒绝溢价 专职司机 统一车辆"
            },{
                mainTitle: "顺风车",
                subTitle: "免费合乘 公益互助 环保共创 快乐同行"
            },{
                mainTitle: "企业用车",
                subTitle: "专业服务 节约成本 管理透明 提升效率"
            },{
                mainTitle: "语音的士",
                subTitle: " 语音叫车 打表计费 线下结算"
            }],
            advantageData: [{
                src: require('../../static/images/home/one-icon.png'),
                title: "车企平台",
                cont: "作为江汽集团的移动出行战略品牌，和行约车借助江汽集团全产业链竞争优势，创新性提供包括技术平台、运营车辆、维修保养、金融信贷、专业保险等在内的整体解决方案。和行约车承袭了江汽集团在长期生产经营过程中形成的严苛管理体系标准。"
            },{
                src: require('../../static/images/home/two-icon.png'),
                title: "品质服务",
                cont: "车辆、司机统一标准、统筹管理，主打安全、优质、优价、专业的品质服务路线。根据市场容量和用户需求，设计多种满足市场需求的服务产品，以差异化经营给客户带来品质、便捷、超值的出行享受。"
            },{
                src: require('../../static/images/home/three-icon.png'),
                title: "技术赋能",
                cont: "和行约车整合多方资源，借助互联网及大数据技术，对服务行程信息记录、留存，保证平台订单全程可追溯。警企联动，与公安机关深度合作，共同保障司乘安全。"
            },{
                src: require('../../static/images/home/four-icon.png'),
                title: "数据驱动",
                cont: "引入广泛应用于互联网行业的成熟专业算法模型，配合和行约车的数据生态，和行约车APP优化用户端及驾驶员端的派单逻辑，更精准地进行派单决策。  "
            }],
            time: "",
        }
    },
    mounted(){
        this.routerTo(1);
        this.$nextTick(()=>{
            setTimeout(()=>{
                document.activeElement.scrollIntoView(false)
            window.scrollTo(0, 0);
            },30)
        })
        this.nowtime();
        // window.scrollTo(0, 0);
    },
    methods: {
        handleClick(index) {
            this.codeImg = '';
            if(index == 1) {
                this.codeImg = require('../../static/images/home/gzh.jpg')
            }else if(index == 2) {
                this.codeImg = require('../../static/images/home/wx.jpg')
            }else if(index == 3) {
                this.codeImg = require('../../static/images/passengers.png')
            }else {
                this.codeImg = require('../../static/images/driver.png')
            }
            this.flag = true
        },
        maskClick() {
            this.flag = false
        },
        routeClick(index) {
            switch(index) {
                case 0:
                    this.$router.push({path: "/mobile/service/express"}) 
                    break;
                case 1:
                    this.$router.push({path: "/mobile/service/hitchRide"}) 
                    break;
                case 2:
                    this.$router.push({path: "/mobile/service/businessCar"}) 
                    break;
                case 3:
                    this.$router.push({path: "/mobile/service/vioceTaxi"}) 
                    break;
                    
            }
        },
        nowtime() {
            let nowDate = new Date();
            let date = {
                // 获取当前年份
                year: nowDate.getFullYear(),
            };
            this.time = date.year;
        },
    }
}
</script>

<style scoped>
.animate__block{
    /* visibility元素是否可见  visible可见  hidden不可见  inherit从父元素继承visibility属性的值*/
    visibility: visible !important;
}
.animate__animated{
    -webkit-animation-duration: 1s;
    /* 规定完成动画所花费的时间，以秒或毫秒计 */
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    /* 规定在动画开始之前的延迟 */
    animation-delay:0.2s;
}
.animate__fadeInup{
    -webkit-animation-name: FadeInup;
    /* 规定需要绑定到选择器的名称 */
    animation-name: FadeInup;
}
.animate__animated {
    /* 二维码页面 弹出的动画用时 */
    --animate-duration: .5s;
}
.home_box {
    position: relative;
}
.content {
    padding: 0 0 1.15rem;

}
.content .cont {
    color: #2D2D39;
    font-size: .36rem;
    line-height: .36rem;
    margin-top: .9rem;
}
.content .cont .second p {
    font-size: .24rem;
}
.content .cont .second .line {
    width: .4rem;
    height: .01rem;
    background-color: #B3B3B3;
    margin: .29rem 0;
}
.content .cont img {
    width: 100%;
    margin-top: .48rem;
    animation-delay:0.2s;
}
.cont .advantage_img {
    visibility: hidden;
}
.second {
    margin: 0 .48rem 0;
}
.serve {
    margin-top: .48rem;
    padding: 0 0 .48rem 0;
    border-bottom: solid .01rem #e6e6e6;
    visibility: hidden;
}
.serve p {
    line-height: .27rem;
}
.advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: hidden;
}
.advantage img {
    width: 1.48rem!important;
    height: 1.48rem;
}
.advantage span {
    margin: .45rem 0;
    
}
.advantage p {
    line-height: .3rem;
    
}
.about {
    background: #F4F4F4;
    padding: .46rem 0 1rem;
    visibility: hidden;
}
.contact {
    visibility: hidden;
}
.about .second p:first-child {
    margin-top: .48rem;
}
.about .second p {
    color: #81818D;
    text-indent: .4rem;
}
.contact .second .name{
    margin: .46rem 0;
}
.contact .second p {
    margin-bottom: .3rem;
    line-height: .24rem;
    color: #81818D;
}
.contact .second img {
    width: .68rem;
    height: .68rem;
    margin-top: .25rem;
}
.contact .second span {
    display: block;
    width: 2.6rem;
    height: .68rem;
    background: #F1F1F1;
    text-align: center;
    line-height: .75rem;
    border-radius: .34rem;
    margin-top: .48rem;
    font-size: .28rem;
}
.contact .second .app {
    display: flex;
}
.contact .second .app span:first-child {
    margin-right: .68rem;
}
.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(128, 128, 128, .5);
}
.mask img {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@keyframes FadeInup {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
        transform: translateY(30%);
    }
    100%{
        opacity:1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
</style>